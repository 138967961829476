import {LitElement, html, css} from 'lit';
import {animate} from "motion";
import {SignalWatcher, signal} from '@lit-labs/signals';

const category = signal("default");

class ImageScrollerElement extends SignalWatcher(LitElement) {
  static get styles() {
    return css`
    :host {
      display: block;
      width: 25vw;
      /* max-height: 50vh; */
      /* width: 50%; */
      height: 40vh;
      position: relative;
      cursor: pointer;
    }

    /* -- FRONT IMAGE CLASSES -- */
    #front {
      /* The default look */
      transform: translateX(0);
      opacity: 1;
      left: 0;
      z-index: 3;  
      position: relative;
      max-height: 100%;
      width: auto;
    }

    /* -- BEHIND IMAGE CLASSES -- */
    #behind {
      position: absolute;
      z-index: 2;
      max-height: 100%;
      width: auto;
      top: 0;
      left: 30%;
      opacity: 1;
      filter: blur(8px);
    }
    /* -- BACK IMAGE CLASSES -- */
    #back {
      position: absolute;
      z-index: 1;
      /* width: 300px; */
      max-height: 100%;
      width: auto;
      top: 0;
      left: 60%;
      opacity: 0;
      filter: blur(8px);
    }
  `;
  }

  static get properties() {
    return { 
        currentImage: {type: Number}
    };
  }

  constructor() {
    super();

    this.currentImage = 0;

    this._animating = false;

    this.images = {
        "default": [
          "./media/me/soccer.png",
          './media/me/paris.JPG',
          './media/me/ladies.JPG',
          './media/me/stranger.JPG',
          './media/me/dinner.jpg'
        ],
        "f1": [
          "./media/f1/bleachers.JPG",
          "./media/f1/hardrock.JPG",
          "./media/f1/ai.webp"
        ],
        "chewy": [
          "./media/chewy/car.JPG",
          "./media/chewy/couch.JPG",
          "./media/chewy/kitchen.JPG"
        ],
        "shows": [
          "./media/shows/clonewars.jpg",
          "./media/shows/hotd.jpg",
          "./media/shows/severance.jpg",
          "./media/shows/westworld.jpg",
          "./media/shows/invincible.jpg"
        ]
    }
  }

  getIndex(i) {
    return (this.currentImage + i) % this.images[category.get()].length;
  }

  firstUpdated() {
    setInterval(() => {
        if(!this._animating) this.rotateImages();
    }, 2500);

    // this.rotateImages
  }

  rotateImages() {
    if(this._animating) return;
    this._animating = true;
    let front = this.shadowRoot.getElementById("front");
    let behind = this.shadowRoot.getElementById("behind");
    let back = this.shadowRoot.getElementById("back");
    
    // Use completely new animation instances with complete configuration
    const frontAnimation = animate(
      front, 
      { 
        transform: ['translateX(0)', 'translateX(-100px)'],
        opacity: [1, 0]
      }, 
      { duration: 0.5, easing: "ease-out" }
    );
    
    const behindAnimation = animate(
      behind, 
      { 
        left: ['30%', '0%'],
        opacity: [1, 1],
        filter: ['blur(8px)', 'blur(0px)']
      }, 
      { duration: 1.0, easing: "ease-out" }
    );
    
    const backAnimation = animate(
      back, 
      { 
        left: ['60%', '30%'],
        opacity: [0, 1],
        filter: ['blur(8px)', 'blur(8px)']
      }, 
      { duration: 1.0, easing: "ease-out" }
    );
    
    // Use Promise.all to wait for all animations to complete
    window.Promise.all([
      frontAnimation.finished, 
      behindAnimation.finished, 
      backAnimation.finished
    ]).then(() => {
        // Update the current image and sources
        this.currentImage = this.getIndex(1);
        
        // Force element recreation by setting styles first, then sources
        front.style.transform = 'translateX(0)';
        front.style.opacity = '1';
        front.style.left = '0';
        front.src = this.images[category.get()][this.currentImage];
        
        behind.style.left = '30%';
        behind.style.opacity = '1';
        behind.style.filter = 'blur(8px)';
        behind.src = this.images[category.get()][this.getIndex(1)];
        
        back.style.left = '60%';
        back.style.opacity = '0';
        back.style.filter = 'blur(8px)';
        back.src = this.images[category.get()][this.getIndex(2)];
        
        this._animating = false;
        this.requestUpdate();
    });

  }
  

  render() {
    return html`
        <img id="front"  src=${this.images[category.get()][this.currentImage]} />
        <img id="behind" src=${this.images[category.get()][this.getIndex(1)]} />
        <img id="back" src=${this.images[category.get()][this.getIndex(2)]} />
    `;
  }
}

window.customElements.define('image-scroller-element', ImageScrollerElement);

export { ImageScrollerElement, category };